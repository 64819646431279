export const masterParameterOptions = {
  ACCOMODATIVE_FACILITY_LENS: ["±2.0", "±1.5", "±1.0"],
  TEMPLATE_TYPES: ["CLINICAL", "SURGICAL"],
  OPHTHALMIC_SPECIALITIES: [
    "GENERAL",
    "CATARACT",
    "GLAUCOMA",
    "RETINA",
    "REFRACTIVE",
    "OCULOPLASTY",
    "PAEDIATRIC",
    "CORNEA",
    "UVEA",
    "NEURO",
    "MEDICAL",
    "OTHERS",
  ],
  GLASS_ADVISE: ["Continue Same Glasses", "Prescribed New Glasses"],
  GLASS_RECOMMENDATION: [
    "Single Vision",
    "Progressive",
    "Bifocal",
    "Trifocal",
    "Multifocal",
    "Executive",
    "Blue Cut",
    "D-Type",
    "Aspheric",
    "Anti-Reflective Coating",
    "Photochromatic",
    "Contact Lens",
  ],
  LATERALITIES: ["BILATERAL", "LEFT", "RIGHT"],
  ORGANS: ["EYE", "SYSTEMIC"],
  ANAESTHESIA: [
    "TOPICAL_ANAESTHESIA",
    "LOCAL_ANAESTHESIA",
    "REGIONAL_ANAESTHESIA",
    "GENERAL_ANAESTHESIA",
    "INTRAVENOUS_ANAESTHESIA",
    "SPINAL_ANAESTHESIA",
    "EPIDURAL_ANAESTHESIA",
    "NERVE_BLOCK",
    "MONITORED_ANAESTHESIA_CARE",
    "TWILIGHT_ANAESTHESIA",
    "CONSCIOUS_SEDATION",
    "INHALATION_ANAESTHESIA",
    "LIDOCAINE_BLOCK",
    "FACIAL_BLOCK",
    "INTRATHECAL_ANAESTHESIA",
    "PERIPHERAL_NERVE_BLOCK",
    "FIELD_BLOCK",
    "NERVE_PLEXUS_BLOCK",
    "DIGITAL_BLOCK",
    "WALANT_ANAESTHESIA",
  ],
  SURGERY_INVESTIGATION_OPTIONS: [
    "BIOMETRY_OCT_SPECULAR",
    "BIOMETRY_OCT_SYRINGING_SPECULAR",
    "OCT_DFP",
    "ECG_X-RAY_PPBS",
  ],
  NEXT_STEP_OPTIONS: ["REVIEW", "CONSULT", "REFER", "IMAGING/LABS"],
  EYE_ANATOMICAL_PARTS: [
    "EYE_GENERAL",
    "ORBIT",
    "EXTRA_OCULAR_MUSCLES",
    "LACRIMAL_SAC",
    "LIDS_AND_ADNEXA",
    "CONJUNCTIVA",
    "SCLERA",
    "CORNEA",
    "ANTERIOR_CHAMBER",
    "LENS",
    "IRIS",
    "PUPIL",
    "CHOROID",
    "CILIARY_BODY",
    "UVEA",
    "VITREOUS",
    "RETINA",
    "DISC",
    "MACULA",
  ],
  DISTANCE_VISUAL_ACUITY: [
    "6/5",
    "6/6",
    "6/6P",
    "6/9",
    "6/9P",
    "6/12",
    "6/12P",
    "6/18",
    "6/18P",
    "6/24",
    "6/24P",
    "6/36",
    "6/36P",
    "1/36",
    "2/36",
    "3/36",
    "6/60",
    "6/60P",
    "5/60",
    "5/60P",
    "4/60",
    "4/60P",
    "3/60",
    "3/60P",
    "2/60",
    "2/60P",
    "1/60",
    "1/60P",
    "FC 3m",
    "FC 2m",
    "FC 1m",
    "FC 1/2m",
    "FCCF",
    "HMCF",
    "HM+ PL accurate",
    "HM+ PL inaccurate",
    "HM+ PR accurate",
    "HM+ PR inaccurate",
    "PL+ PR accurate",
    "PL+ PR inaccurate",
    "PL+ PR-",
    "PL- PR accurate",
    "PL- PR inaccurate",
    "CSM",
    "F&F - Light",
    "F&F - Object",
    "No Fixation",
    "Winces to Light",
    "PL- PR-",
    "No Improvement",
  ],
  NEAR_VISUAL_ACUITY: [
    "N3",
    "N4",
    "N5",
    "N6",
    "N8",
    "N10",
    "N12",
    "N14",
    "N18",
    "N24",
    "N36",
    "N48",
    "N60",
    "N5P",
    "N6P",
    "N8P",
    "N10P",
    "N12P",
    "N14P",
    "N18P",
    "N24P",
    "N36P",
    "<N36",
    "N48P",
    "<N48",
    "N60P",
  ],
  DIOPTRES: [
    "0.00",
    "+0.25",
    "+0.50",
    "+0.75",
    "+1.00",
    "+1.25",
    "+1.50",
    "+1.75",
    "+2.00",
    "+2.25",
    "+2.50",
    "+2.75",
    "+3.00",
    "+3.25",
    "+3.50",
    "+3.75",
    "+4.00",
    "+4.25",
    "+4.50",
    "+4.75",
    "+5.00",
    "+5.25",
    "+5.50",
    "+5.75",
    "+6.00",
    "+6.25",
    "+6.50",
    "+6.75",
    "+7.00",
    "+7.25",
    "+7.50",
    "+7.75",
    "+8.00",
    "+8.25",
    "+8.50",
    "+8.75",
    "+9.00",
    "+9.25",
    "+9.50",
    "+9.75",
    "+10.00",
    "+10.25",
    "+10.50",
    "+10.75",
    "+11.00",
    "+11.25",
    "+11.50",
    "+11.75",
    "+12.00",
    "+12.25",
    "+12.50",
    "+12.75",
    "+13.00",
    "+13.25",
    "+13.50",
    "+13.75",
    "+14.00",
    "+14.25",
    "+14.50",
    "+14.75",
    "+15.00",
    "+15.25",
    "+15.50",
    "+15.75",
    "+16.00",
    "+16.25",
    "+16.50",
    "+16.75",
    "+17.00",
    "+17.25",
    "+17.50",
    "+17.75",
    "+18.00",
    "+18.25",
    "+18.50",
    "+18.75",
    "+19.00",
    "+19.25",
    "+19.50",
    "+19.75",
    "+20.00",
    "+20.25",
    "+20.50",
    "+20.75",
    "+21.00",
    "+21.25",
    "+21.50",
    "+21.75",
    "+22.00",
    "+22.25",
    "+22.50",
    "+22.75",
    "+23.00",
    "+23.25",
    "+23.50",
    "+23.75",
    "+24.00",
    "+24.25",
    "+24.50",
    "+24.75",
    "+25.00",
    "+25.25",
    "+25.50",
    "+25.75",
    "+26.00",
    "+26.25",
    "+26.50",
    "+26.75",
    "+27.00",
    "+27.25",
    "+27.50",
    "+27.75",
    "+28.00",
    "+28.25",
    "+28.50",
    "+28.75",
    "+29.00",
    "+29.25",
    "+29.50",
    "+29.75",
    "+30.00",
    "+30.25",
    "+30.50",
    "+30.75",
    "+31.00",
    "+31.25",
    "+31.50",
    "+31.75",
    "+32.00",
    "+32.25",
    "+32.50",
    "+32.75",
    "+33.00",
    "+33.25",
    "+33.50",
    "+33.75",
    "+34.00",
    "+34.25",
    "+34.50",
    "+34.75",
    "+35.00",
    "+35.25",
    "+35.50",
    "+35.75",
    "+36.00",
    "+36.25",
    "+36.50",
    "+36.75",
    "+37.00",
    "+37.25",
    "+37.50",
    "+37.75",
    "+38.00",
    "+38.25",
    "+38.50",
    "+38.75",
    "+39.00",
    "+39.25",
    "+39.50",
    "+39.75",
    "+40.00",
    "-0.25",
    "-0.50",
    "-0.75",
    "-1.00",
    "-1.25",
    "-1.50",
    "-1.75",
    "-2.00",
    "-2.25",
    "-2.50",
    "-2.75",
    "-3.00",
    "-3.25",
    "-3.50",
    "-3.75",
    "-4.00",
    "-4.25",
    "-4.50",
    "-4.75",
    "-5.00",
    "-5.25",
    "-5.50",
    "-5.75",
    "-6.00",
    "-6.25",
    "-6.50",
    "-6.75",
    "-7.00",
    "-7.25",
    "-7.50",
    "-7.75",
    "-8.00",
    "-8.25",
    "-8.50",
    "-8.75",
    "-9.00",
    "-9.25",
    "-9.50",
    "-9.75",
    "-10.00",
    "-10.25",
    "-10.50",
    "-10.75",
    "-11.00",
    "-11.25",
    "-11.50",
    "-11.75",
    "-12.00",
    "-12.25",
    "-12.50",
    "-12.75",
    "-13.00",
    "-13.25",
    "-13.50",
    "-13.75",
    "-14.00",
    "-14.25",
    "-14.50",
    "-14.75",
    "-15.00",
    "-15.25",
    "-15.50",
    "-15.75",
    "-16.00",
    "-16.25",
    "-16.50",
    "-16.75",
    "-17.00",
    "-17.25",
    "-17.50",
    "-17.75",
    "-18.00",
    "-18.25",
    "-18.50",
    "-18.75",
    "-19.00",
    "-19.25",
    "-19.50",
    "-19.75",
    "-20.00",
    "-20.25",
    "-20.50",
    "-20.75",
    "-21.00",
    "-21.25",
    "-21.50",
    "-21.75",
    "-22.00",
    "-22.25",
    "-22.50",
    "-22.75",
    "-23.00",
    "-23.25",
    "-23.50",
    "-23.75",
    "-24.00",
    "-24.25",
    "-24.50",
    "-24.75",
    "-25.00",
    "-25.25",
    "-25.50",
    "-25.75",
    "-26.00",
    "-26.25",
    "-26.50",
    "-26.75",
    "-27.00",
    "-27.25",
    "-27.50",
    "-27.75",
    "-28.00",
    "-28.25",
    "-28.50",
    "-28.75",
    "-29.00",
    "-29.25",
    "-29.50",
    "-29.75",
    "-30.00",
    "-30.25",
    "-30.50",
    "-30.75",
    "-31.00",
    "-31.25",
    "-31.50",
    "-31.75",
    "-32.00",
    "-32.25",
    "-32.50",
    "-32.75",
    "-33.00",
    "-33.25",
    "-33.50",
    "-33.75",
    "-34.00",
    "-34.25",
    "-34.50",
    "-34.75",
    "-35.00",
    "-35.25",
    "-35.50",
    "-35.75",
    "-36.00",
    "-36.25",
    "-36.50",
    "-36.75",
    "-37.00",
    "-37.25",
    "-37.50",
    "-37.75",
    "-38.00",
    "-38.25",
    "-38.50",
    "-38.75",
    "-39.00",
    "-39.25",
    "-39.50",
    "-39.75",
    "-40.00",
  ],
  AXIS: [
    "0°",
    "5°",
    "10°",
    "15°",
    "20°",
    "25°",
    "30°",
    "35°",
    "40°",
    "45°",
    "50°",
    "55°",
    "60°",
    "65°",
    "70°",
    "75°",
    "80°",
    "85°",
    "90°",
    "95°",
    "100°",
    "105°",
    "110°",
    "115°",
    "120°",
    "125°",
    "130°",
    "135°",
    "140°",
    "145°",
    "150°",
    "155°",
    "160°",
    "165°",
    "170°",
    "175°",
    "180°",
  ],
  PUPILLARY_REACTION: [
    "RRR",
    "NSNR",
    "NSRL",
    "Sluggish",
    "Brisk",
    "Non-Reactive",
    "RAPD",
    "Pharmacologically Dilated",
    "Fixed Dilated",
  ],
  CONSTRAST_SENSITIVITY: [
    "0.00",
    "0.05",
    "0.10",
    "0.15",
    "0.20",
    "0.25",
    "0.30",
    "0.35",
    "0.40",
    "0.45",
    "0.50",
    "0.55",
    "0.60",
    "0.65",
    "0.70",
    "0.75",
    "0.80",
    "0.85",
    "0.90",
    "0.95",
    "1.00",
    "1.05",
    "1.10",
    "1.15",
    "1.20",
    "1.25",
    "1.30",
    "1.35",
    "1.40",
    "1.45",
    "1.50",
    "1.55",
    "1.60",
    "1.65",
    "1.70",
    "1.75",
    "1.80",
    "1.85",
    "1.90",
    "1.95",
    "2.00",
    "2.05",
    "2.10",
    "2.15",
    "2.20",
    "2.25",
    "2.30",
    "2.35",
    "2.40",
    "2.45",
    "2.50",
  ],
  COLOUR_VISION: [
    "Not Tested",
    "Normal",
    "Anomaly",
    "Protanomaly",
    "Deuteranomaly",
    "Protanopia",
    "Deuteranopia",
    "Monochromatopsia",
    "Tritanomaly",
    "Tritanopia",
  ],
  COLOUR_VISION_TEST: [
    "Ishihara",
    "Farnsworth-Munsell 100 Hue",
    "Farnsworth D15",
    "HRR",
    "City University",
    "Anomaloscope",
    "Lantern",
    "Cambridge",
  ],
  SCHIRMER: [
    "0 mm",
    "1 mm",
    "2 mm",
    "3 mm",
    "4 mm",
    "5 mm",
    "6 mm",
    "7 mm",
    "8 mm",
    "9 mm",
    "10 mm",
    "11 mm",
    "12 mm",
    "13 mm",
    "14 mm",
    "15 mm",
    "16 mm",
    "17 mm",
    "18 mm",
    "19 mm",
    "20 mm",
    "21 mm",
    "22 mm",
    "23 mm",
    "24 mm",
    "25 mm",
    "26 mm",
    "27 mm",
    "28 mm",
    "29 mm",
    "30 mm",
    "31 mm",
    "32 mm",
    "33 mm",
    "34 mm",
    "35 mm",
    "More than 35 mm",
  ],
  SCHIRMER_DURATION: [
    "1 min",
    "2 min",
    "3 min",
    "4 min",
    "5 min",
    "6 min",
    "7 min",
    "8 min",
    "9 min",
    "10 min",
  ],
  WORTH: [
    "Normal Binocular Response",
    "Harmonious ARC with Manifested Squint",
    "Uncrossed Diplopia with Esotropia",
    "Crossed Diplopia with Exotropia",
    "Right Eye Suppressed",
    "Left Eye Suppressed",
  ],
};
